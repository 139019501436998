import { GraphQLClient, Variables } from 'graphql-request'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import {
    ActiveFacility,
    activeFacilityInjectionKey
} from '~/composables/use-active-facility'
import { ClientError } from 'graphql-request/src/types'
import { useShowAlert } from '~/composables/use-show-alert'
import type { InjectionKey, Ref } from 'vue'
import { inject } from 'vue'
import { useLayoutMode } from '~/composables/use-layout-mode'
import { version } from '~/composables/meta'

export const graphqlClientInjectionKey: InjectionKey<GraphQLClient> = Symbol(
    'graphqlClientInjectionKey'
)

/**
 * @param orgLevel Provide as true to execute a query with an active facility (usually to determine the active facility)
 */
export function useRpQuery({ orgLevel }: { orgLevel?: boolean } = {}) {
    const showAlert = useShowAlert()
    let activeFacility: Ref<ActiveFacility | undefined> | undefined = undefined
    if (!orgLevel) {
        activeFacility = inject(activeFacilityInjectionKey)
    }
    const client = inject(graphqlClientInjectionKey)
    if (client === undefined) {
        throw new Error('No graphql client provided')
    }
    const { isKiosk } = useLayoutMode()

    async function clientQuery<T, V extends Variables | undefined>(
        document: TypedDocumentNode<T, V>,
        variables?: V,
        requestHeaders?: Record<string, string>
    ): Promise<T> {
        if (client === undefined) {
            throw new Error('No graphql client provided')
        }
        return client.request(document, variables, requestHeaders)
    }

    async function runQuery<T, V extends Variables | undefined>(
        document: TypedDocumentNode<T, V>,
        variables?: V
    ): Promise<T> {
        try {
            const requestHeaders: Record<string, string> = {}
            if (activeFacility !== undefined && activeFacility.value) {
                requestHeaders['RPHQ-Facility'] = activeFacility.value.id
            }
            if (isKiosk.value) {
                requestHeaders['RPHQ-Mode'] = 'KioskMode'
            }

            return await clientQuery(document, variables, requestHeaders)
        } catch (e) {
            const error = e as ClientError
            if (
                Array.isArray(error?.response?.errors) &&
                error.response.errors.length > 0
            ) {
                const first = error.response.errors[0]
                if (first.extensions !== undefined) {
                    if (first.extensions.validation !== undefined) {
                        throw error
                    } else if (
                        first.extensions.category === 'user-reportable'
                    ) {
                        if (process.client) {
                            await showAlert(
                                first.extensions.title as string,
                                first.extensions.message as string
                            )
                        }
                    } else {
                        if (process.client) {
                            if (first.extensions.debugMessage !== undefined) {
                                await showAlert(
                                    'Internal Server Error',
                                    first.extensions.debugMessage as string
                                )
                            } else {
                                if (
                                    needsNewVersion(
                                        error.response?.headers?.get(
                                            'redpointhq-version'
                                        )
                                    )
                                ) {
                                    await showAlert(
                                        'New Version Available',
                                        'Whoops! It looks like you were viewing an old version of this page. Click "OK" to refresh.'
                                    ).then(() => {
                                        location.reload()
                                    })
                                } else {
                                    await showAlert(
                                        'Internal Server Error',
                                        first.message
                                    )
                                }
                            }
                        }
                    }
                } else if (process.client) {
                    await showAlert('Internal Server Error', first.message)
                }
            }

            console.error(error)
            throw error
        }
    }

    return <T, V extends Variables | undefined>(
        document: TypedDocumentNode<T, V>,
        variables?: V
    ): Promise<T> => {
        return runQuery(document, variables)
    }
}

function needsNewVersion(serverVersion: unknown) {
    if (
        typeof serverVersion === 'string' &&
        /^[0-9]+\.[0-9]+\.[0-9]+/.test(serverVersion)
    ) {
        const parts = serverVersion.split('.')
        const major = parseInt(parts[0])
        const minor = parseInt(parts[1])
        const patch = parseInt(parts[2])
        if (major > version.major) {
            return true
        } else if (major === version.major && minor > version.minor) {
            return true
        } else if (
            major === version.major &&
            minor === version.minor &&
            patch > version.patch
        ) {
            return true
        }
    } else {
        console.error('Invalid server version: ' + serverVersion)
    }

    return false
}
